import React from 'react'
import style from './UnProtected.module.css'
import { Navigate } from 'react-router-dom';

export default function UnProtected(props) {
  console.log(props);
  if(!localStorage.getItem('token')){
   return props.children
  }
  else{
   return <Navigate to={'/'}></Navigate>
  }
}
