import React from 'react'
import style from './ReturnPolicy.module.css'
export default function ReturnPolicy() {
  return <>
  <div className={`${style.container1} d-flex justify-content-center`}>
    <p className={`${style.para} text-white text-center mt-5`}>سياسة الاسترجاع</p>
  </div>
  <p className={`${style.para11} pe-4 pe-sm-5 ps-4 `}>سياسة الاسترجاع</p>
  <p className={`${style.para1} pe-4 pe-sm-5 ps-4`}>لأن أكاديمية إتقان يقدم سلعا غير مادية ” رقمية ” غير قابلة للإلغاء ، فإننا لا نصدر المبالغ المستردة بمجرد
ً  نظر ً
  إتمام الخدمة وإرسال المنتج.</p>
  <p className={`${style.para1} pe-4 pe-sm-5 ps-4`}>كعميل ، أنت مسؤول عن فهم ذلك عند شراء أي منتج في موقعنا.</p>
  <p className={`${style.para1} pe-4 pe-sm-5 ps-4`}>ومع ذلك ، فإننا ندرك أن ظروفًا  استثنائية يمكن أن تحدث فيما يتعلق بطبيعة المنتج الذي نوفره. لذلك ، فإننا نلتزم بطلبات الاسترداد للأسباب التالية</p>
  <div className="digital">
  <p  className={`${style.para11} pe-4 pe-sm-5 ps-4 `}>خدمات المنتجات الرقمية</p>
    <p className={`${style.para2}  pe-4 pe-sm-5 mb-5 ps-4`}> <i className="fa-solid fa-circle fs-6 ms-3 mb-2"></i> لم يتم تسليم الخدمة: تم إلغاء الخدمة بناء على طلب المشتري قبل معالجة الطلب.</p>
    <p className={`${style.para2}  pe-4 pe-sm-5 mb-5 ps-4`}> <i className="fa-solid fa-circle fs-6 ms-3 mb-2"></i> تأخر تقديم الخدمة : لا يمكن تسليم الخدمة ضمن الإطار الزمني المحدد ، بسبب بعض المشكلات في نهاية مزود الخدمة،
    فــــــى مدة أقصاها ١٥ يوما .</p>
    <p className={`${style.para2}  pe-4 pe-sm-5 mb-5 ps-4`}> <i className="fa-solid fa-circle fs-6 ms-3 mb-2"></i>رجى ملاحظة أننا لا نتحمل أي مسؤولية عن عدم كفائة جهاز الحاسب الشخصـــى لديــــك وتوافقه مــــع البرامج المباعـــــــــة فى الموقع
 وبالتالي فإننا لا نلبي أي طلبات استرداد / إرجاع / استبدال بناء على عدم توافق منتجاتنا وخدماتنا مع الأجهزة والعتاد الخاص بك</p>
    <p className={`${style.para2}  pe-4 pe-sm-5 mb-5 ps-4`}> <i className="fa-solid fa-circle fs-6 ms-3 mb-2"></i>يتم قبول طلبات استرداد الأموال في غضون أسبوعين بعد تقديم الطلب. يجب عليك إرفاق هذا الطلب بأسباب تفصيلية 
    ومسببة لطلبك لاسترداد الأموال</p>
    <p className={`${style.para2}  pe-4 pe-sm-5 mb-5 ps-4`}><i className="fa-solid fa-circle fs-6 ms-3 mb-2"></i>يرجى التأكد من أن طلبك لا يتعارض مع الشروط والأحكام الخاصة بنا</p>
    <p className={`${style.para2}  pe-4 pe-sm-5 mb-5 ps-4`}><i className="fa-solid fa-circle fs-6 ms-3 mb-2"></i>فى حال تم تقديم طلب الاسترداد بعد ١٥ يوم من الشراء لن يتم قبول طلب الاسترداد
    </p>

    
  </div>
  <p className={`${style.para12} pe-4 pe-sm-5 ps-4 `}>تحتفظ شركتنا بالحق في تغير أو تعديل الشروط و الأحكام دون إشعار مسبق </p>

  </>
}
