import React from 'react'
import style from './Terms.module.css'
export default function Terms() {
  return <>
  <div className={`${style.container1} d-flex justify-content-center`}>
    <p className={`${style.para} text-white text-center mt-5`}>الشروط و الأحكــــــام</p>
  </div>
  <p className={`${style.para11} pe-4 ps-4 `}>شروط استخدام موقع أكاديمية إتقان
  </p>
  <div className="information">
  <p className={`${style.para1}  my-3 my-sm-5 pe-4 ps-4` }>١ - معلومات مهمـــــة</p>
  <p className={`${style.para2}  pe-4 mb-4 ps-4`}> <i className="fa-solid fa-circle fs-6 ms-3 mb-2"></i>يجب أن تقرأ بعناية الشروط و الأحكام التالية .
  </p>
  <p className={`${style.para2}  pe-4 mb-5 ps-4`}> <i className="fa-solid fa-circle fs-6 ms-3"></i>إن شرائك أو استخدامك لمنتجاتنا أو خدماتنا تعني أنك قد قرأت هذه الشروط و الأحكام ووافقت عليها .
  </p>
  </div>
  <div className="sign my-5">
  <p className={`${style.para1}  my-3 my-sm-5 pt-2 pt-sm-5 pe-4 ps-4`}>٢ - التسجيل / الشراء</p>
  <p className={`${style.para2}  pe-4 mb-4 ps-4`}> <i className="fa-solid fa-circle fs-6 ms-3 mb-2"></i>قد يتطلب شراء خدمة أو منتج من الموقع التسجيل .
  </p>
  <p className={`${style.para2}  pe-4 mb-4 ps-4`}> <i className="fa-solid fa-circle fs-6 ms-3"></i> إذا تم إجراء التسجيل , فإنك توافق على تزويدنا بمعلومات تسجيل أو شراء دقيق و كامل 
  </p>
  <p className={`${style.para2}  pe-4 mb-4 ps-4`}> <i className="fa-solid fa-circle fs-6 ms-3"></i> يجب أن يتم تسجيلك باستخدام معلومات دقيقة
  </p>
  <p className={`${style.para2}  pe-4 mb-4 ps-4`}> <i className="fa-solid fa-circle fs-6 ms-3"></i> كل تسجيل لاستخدامك الشخصي فقط
  </p>
  <p className={`${style.para2}  pe-4 mb-5 ps-4`}> <i className="fa-solid fa-circle fs-6 ms-3"></i>  يمنع منعا باتًا مشاركة العضوية مع الغير . ويجب على العميل إبلاغنا في حالة تمت ملاحظة حركة مجهولة بالحساب أو إذا تمت سرقتها .

  </p>
  </div>
  <div className="sign my-5">
  <p className={`${style.para1}  my-4 my-sm-5 pe-4 ps-4 pt-0 pt-sm-5`}>لا نسمح بما يلي : </p>
  <p className={`${style.para2}  pe-4 mb-4 mb-4 ps-4`}> <i className="fa-solid fa-circle fs-6 ms-3 mb-2"></i>أي شخص أخر يستهدم الأقسام المسجلة تحت اسمك أو ,
  </p>
  <p className={`${style.para2}  pe-4 mb-4 ps-4`}> <i className="fa-solid fa-circle fs-6 ms-3"></i> إتاحة الوصول من خلال اسم واحد لعدة مستخدمين على الشبكة 
  </p>
  <p className={`${style.para2}  pe-4 mb-4 ps-4`}> <i className="fa-solid fa-circle fs-6 ms-3"></i> أنت مسؤول عن منع هذ الاستخدام الغير مصرح به
  </p>
  <p className={`${style.para2}  pe-4 mb-5 ps-4`}> <i className="fa-solid fa-circle fs-6 ms-3"></i> يحتفظ موقع أكاديمية إتقان بالحق الوحيد و السلطة لقبول ً أو رفض تسجيلك أو إنتهاء التسجيل بمجرد فبوله , لأي سبب يراه مناسبا
  </p>
  </div>
  <div className="pays my-5">
  <p className={`${style.para1}  my-3 my-sm-5 pt-2 pt-sm-5 pe-4 ps-4`}>٣ - المدفوعـــــات</p>
  <p className={`${style.para2}  pe-4 mb-4 ps-4`}> <i className="fa-solid fa-circle fs-6 ms-3 mb-2"></i>أنت تقر وتضمن أنه إذا كنت تشتري شيئا
  ً منا , فإن أي معلومة ائتمانية تقدمها صحيحة وكاملة
  </p>
  <p className={`${style.para2}  pe-4 mb-4 ps-4 `}> <i className="fa-solid fa-circle fs-6 ms-3"></i>جميع الأسعار الموجودة في الموقع هي بالجنية المصري 
  </p>
  <p className={`${style.para2}  pe-4 mb-5 ps-4 `}> <i className="fa-solid fa-circle fs-6 ms-3"></i>يتم تسليم الطلبات بحد أقصى ٤٨ ساعة من إستلام المبلغ . إذا تأخر تسليم طلبك بعد سداد المبلغ لمدة تزيد عن ٤٨ ساعة . فضلا
  ً قم بالأتصال بنا .  
  </p>
  </div>
  <div className="pays my-5">
  <p className={`${style.para1}  my-3 my-sm-5 pt-2 pt-sm-5 pe-4 ps-4`}>٤ - حقــــوق الملكيــــة</p>
  <p className={`${style.para2}  pe-4 mb-4 ps-4`}> <i className="fa-solid fa-circle fs-6 ms-3 mb-2"></i>يتم منح دخول للموقع للمشترك باكاديمية إتقان ( لمشاهدة المواد التعليمية فقط بدون تحميل أو تصوير ) 
  </p>
  <p className={`${style.para2}  pe-4 mb-4 ps-4`}> <i className="fa-solid fa-circle fs-6 ms-3 mb-2"></i> لا يجوز للمشترك مشاركة حسابه مع أي شخص باي حال من الأحـوال .  
  </p>
  <p className={`${style.para2}  pe-4 mb-4 ps-4 `}> <i className="fa-solid fa-circle fs-6 ms-3"></i>لا يجوز مشاركة أو نشر المادة التعليمية من المواد المتوفرة على موقع الأكاديمية و اذا تم ذالك فيعتبر انتهاك لحقوق الملكية الفكرية وسيتم أبلاغ السلطات المعنية .   
  </p>
  <p className={`${style.para2}  pe-4 mb-4 ps-4 `}> <i className="fa-solid fa-circle fs-6 ms-3"></i>ستم حظر المشترك نهائيا ً من دخول الموقع في الحالات التالية : أرتباط حسابة بحسابات أخرى - تصوير المحاضرات - نشر المحاضرات . </p>
  <p className={`${style.para2}  pe-4 mb-5 ps-4 `}> <i className="fa-solid fa-circle fs-6 ms-3"></i>يتم توفير المواد على موقع على شبكة الإنترنت من أكاديمية إتقان كما هي بدون أي ضمانات فيما يتعلق بسرعة خدمات الإنترنت و علاوة على ذالك . </p>

  </div>
  <p className={`${style.para12} pe-4 ps-4 `}>تحتفظ شركتنا بالحق في تغير أو تعديل الشروط و الأحكام دون إشعار مسبق </p>
  </>
}
