import { createContext, useState } from "react";

export let BaseUrlContext=createContext();

export default function BaseUrlContextProvider({children}){
  let [baseUrl,setBaseUrl]=useState('https://etqan-62b1ee78de4c.herokuapp.com/');
  return <BaseUrlContext.Provider value={{baseUrl,setBaseUrl}}>
    {children}
  </BaseUrlContext.Provider>
}

