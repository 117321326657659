import React from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import Layout from './Components/Layout/Layout'
import Home from './Components/Home/Home'
import Who from './Components/Who/Who'
import Register from './Components/Register/Register'
import Terms from './Components/Terms/Terms'
import ReturnPolicy from './Components/ReturnPolicy/ReturnPolicy'
import Courses from './Components/Courses/Courses'
import Profile from './Components/Profile/Profile'
import ContactUs from './Components/ContactUs/ContactUs'
import Login from './Components/Login/Login'
import Protectedroute from './Components/Protectedroute/Protectedroute'
import WithdrawPoints from './Components/WithdrawPoints/WithdrawPoints'
import UnProtected from './Components/UnProtected/UnProtected'
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy'
import BaseUrlContextProvider from './Contexts/BaseUrlContext'
import UserProfile from './Components/UserProfile/UserProfile';
import VerifyEmail from './Components/VerifyEmail/VerifyEmail';
export default function App() {
  let routers = createBrowserRouter([
    {path:'', element:<Layout/>,children:[
      {index:true,element:<Home/>},
      {path:'Who',element:<Who/>},
      {path:'Register',element:<UnProtected><Register/></UnProtected>},
      {path:'Terms',element:<Terms/> },
      {path:'User/:slug',element:<UserProfile/> },
      {path:'Return',element:<ReturnPolicy/> },
      {path:'courses/:slug',element:<Courses/>},
      {path:'My-Profile',element:<Protectedroute><Profile/></Protectedroute>},
      {path:'contact',element:<ContactUs/>},
      {path:'login',element:<UnProtected><Login/></UnProtected>},
      {path:'Withdraw',element:<Protectedroute><WithdrawPoints/></Protectedroute>},
      {path:'Privacy',element:<PrivacyPolicy/>},
      {path:'api/email/verify/:id/:hash', element:<VerifyEmail/>},
    ]}
  ])
  return <>
    <BaseUrlContextProvider>
      <RouterProvider router={routers}></RouterProvider>
    </BaseUrlContextProvider>
  </>
}
