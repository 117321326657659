import React, { useContext, useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BaseUrlContext } from '../../Contexts/BaseUrlContext';

export default function VerifyEmail() {
  const { id, hash } = useParams();
  const location = useLocation();
  const navigate = useNavigate(); // لإضافة وظيفة التوجيه
  const [verificationStatus, setVerificationStatus] = useState(null);
  const { baseUrl } = useContext(BaseUrlContext);

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await axios.get(`${baseUrl}api/email/verify/${id}/${hash}${location.search}`);
        setVerificationStatus('Email verified successfully!');
      } catch (error) {
        setVerificationStatus('Error verifying email.');
      }
    };

    verifyEmail();
  }, [id, hash, location.search]);

  const handleLoginRedirect = () => {
    navigate('/login'); 
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>{verificationStatus ? verificationStatus : 'Verifying your email...'}</h1>
      {verificationStatus && (
        <button 
        className='btn btn-outline-danger'
          onClick={handleLoginRedirect} 
          style={{ marginTop: '20px', marginBottom:'20px', padding: '10px 20px', fontSize: '16px', cursor: 'pointer' }}
        >
          العودة لتسجيل الدخول
        </button>
      )}
    </div>
  );
}
