import React from 'react'
import style from './Footer.module.css'
import { Link } from 'react-router-dom'
export default function Footer() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return <>
  <div className={`${style.container1} text-center text-sm-end pt-5 pb-3`}>
  <div className="container" style={{direction:'rtl'}}>
    <div className="row ">
    <div className="col-md-4 col-sm-6">
        <p className='mb-2 text-white' style={{fontFamily:'IBM Plex Sans Arabic', fontWeight:'700',fontSize:'35px'}}>روابط سريعة</p>
        <Link style={{textDecoration:'none'}} to={'/Terms'}><p className='mb-2 text-white' style={{fontFamily:'IBM Plex Sans Arabic', fontWeight:'100',fontSize:'22px'}} onClick={scrollToTop}>الشروط و الاحكــــــام</p> </Link>
        <Link style={{textDecoration:'none'}} to={'/Return'}><p className='mb-2 text-white' style={{fontFamily:'IBM Plex Sans Arabic', fontWeight:'100',fontSize:'22px'}} onClick={scrollToTop}>سياسة الإسترجــــــاع</p> </Link>
        <Link style={{textDecoration:'none'}} to={'/Privacy'}><p className='text-white' style={{fontFamily:'IBM Plex Sans Arabic', fontWeight:'100',fontSize:'22px'}} onClick={scrollToTop}>سياسة الخصوصية</p> </Link>
      </div>
      <div className={`col-md-4 col-sm-6 ${style.nt}`}>
      <p className='mb-2 text-white' style={{fontFamily:'IBM Plex Sans Arabic', fontWeight:'700',fontSize:'35px'}}>تواصل معنا</p>
      <p className='mb-2 text-white' style={{fontFamily:'IBM Plex Sans Arabic', fontWeight:'100',fontSize:'22px'}}>
        <a href="tel:01117772866" style={{textDecoration: 'none', color: 'white'}}>اتصل بنا</a>
      </p>
      <div className={`d-flex ${style.ft}`}>
    <a href="https://www.facebook.com/share/ABLHY2PFUJ8ePywH/?mibextid=LQQJ4d" className="">
      <i className="bi bi-facebook" style={{color: 'white', fontSize: '20px'}}></i>
    </a>
    <a href="https://www.instagram.com/etqanacademic/?igsh=MTZheXY3c3NhaDFtYQ%3D%3D&utm_source=qr" className="me-3">
      <i className="bi bi-instagram" style={{color: 'white', fontSize: '20px'}}></i>
    </a>
  
    <a href="mailto:etqanacademy29@gmail.com" className="me-3">
      <i className="bi bi-google" style={{color: 'white', fontSize: '20px'}}></i>
    </a>
    <a href="https://wa.me/+201140661627" className="me-3">
      <i className="bi bi-whatsapp" style={{color: 'white', fontSize: '20px'}}></i>
    </a>

  </div>
      </div>
      <div className={`col-md-4 ${style.nt}`}>
      <p className='mb-2 text-white' style={{fontFamily:'IBM Plex Sans Arabic', fontWeight:'700',fontSize:'35px'}}>النشرة البريدية</p>
      <p className='mb-2 text-white' style={{fontFamily:'IBM Plex Sans Arabic', fontWeight:'100',fontSize:'22px'}}>بإشتراكك في النشرة البريدية لأكاديمية إتقان ستتلقى
      أحدث الأخبار و العروض و الدورات الجديدة .</p> 
      <input placeholder='البريد الإلكترونــــي' type="email" className={`${style.trans} form-control pe-2`}  />
      <button className='w-100 btn text-center bg-white mt-4' style={{fontFamily:'IBM Plex Sans Arabic',  fontWeight:'500',fontSize:'20px', height:'50px'}}>إشترك في النشرة البريدية
      </button>
      </div>
      <div className="col-md-12" style={{marginTop:'60px'}}>
        <div className='w-100' style={{height:'0.8px' , backgroundColor:'white'}}></div>
      </div>
      <div className="col-md-12" style={{marginTop:'20px'}}>
        <p className='text-center text-white' style={{fontFamily:'IBM Plex Sans Arabic', fontSize:'30px', fontWeight:'500'}}>أكاديمية إتـــــــــــــقان ٢٠٢١ - جميع الحقوق محفوظة </p>
      </div>
    </div>
  </div>
  </div>
  </>
}
