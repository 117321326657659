import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { BaseUrlContext } from '../../Contexts/BaseUrlContext';
import style from './UserProfile.module.css';
import personalLogo from '../../Assets/images/أيقون-الصفحة-الشخصية.png';
import Courses from '../../Assets/images/أيقون-الدورات.png';
import Designs from '../../Assets/images/أيقون-التصميمات.png';
import Points from '../../Assets/images/ايقون-النقاط.png';
import TotalPoints from '../../Assets/images/ايقون-اجمالي-النقاط.png';
import GalleryImage from '../../Assets/images/معرض-اعمالي.png';
import { toast, Toaster } from 'react-hot-toast';

export default function UserProfile() {
  const [profileImage, setProfileImage] = useState(personalLogo);
  const { slug } = useParams(); 
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null); 
  const { baseUrl } = useContext(BaseUrlContext);
  const [showImage, setShowImage] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  useEffect(() => {
    async function fetchUserData() {
      try {
        const token = localStorage.getItem('token');
  
        const response = await axios.get(`${baseUrl}api/students/${slug}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        const userData = response.data.data;
        setUserData(userData);
  
        if (userData.picture) {
          setProfileImage(userData.picture);
        }
  
      } catch (error) {
        console.error('Error fetching user data', error);
        setError('User not found or API error');
      }
    }
  
    fetchUserData();
  }, [slug, baseUrl]);

  const videoTypes = ['mp4', 'avi', 'mkv', 'mov', 'wmv', 'flv', 'webm', 'mpg', 'mpeg', 'm4v', '3gp', 'ogv', 'rm', 'ts', 'vob'];

  function isVideoType(type) {
    return videoTypes.includes(type.toLowerCase());
  }

  function openModal(item) {
    setModalContent(item.url);
    setShowImage(true);
  }

  function closeResults() {
    setShowImage(false);
    setModalContent(null);
  }

  async function handleDelete(imageId) {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${baseUrl}api/gallery/${imageId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success('File deleted successfully');
      setUserData(prevState => ({
        ...prevState,
        gallery: prevState.gallery.filter(item => item.id !== imageId)
      }));
    } catch (error) {
      console.error('Error deleting file', error);
      toast.error('An error occurred while deleting the file.');
    }
  }

  if (error) {
    return (
      <div className={style.errorContainer}>
        <h1>{error}</h1>
        <p>Please check the URL or try again later.</p>
      </div>
    );
  }

  return (
    <div className={`${style.profile}`}>
      <Toaster reverseOrder={false} />
      <div className={`${style.information} py-5`}>
        <div className="container text-center">
        <img 
          src={profileImage} 
          alt="أيقون-الصفحة-الشخصية" 
          style={{ width: '200px', height: '200px', objectFit: 'cover', borderRadius: '50%' }} 
        />

          <div className="row mt-3">
            <div className="col-xl-3 col-lg-6 mb-3">
              <p>{userData?.first_name} {userData?.last_name}</p>
            </div>
            <div className="col-xl-3 col-lg-6 mb-3">
              <p>{userData?.job_title}</p>
            </div>
            <div className="col-xl-3 col-lg-6 mb-3">
              <p>{userData?.code}</p>
            </div>
            <div className="col-xl-3 col-lg-6 mb-3">
              <p>{userData?.email}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={`${style.workInformation} py-5 my-4`}>
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-6 text-center">
              <div className={`${style.image} d-flex justify-content-center align-items-center`}>
                <img src={Courses} alt="Courses" />
              </div>
              <h3 className='mb-4'>الدورات</h3>
              <p>{userData?.courses_count} دورة</p>
            </div>
            <div className="col-xl-3 col-lg-6 text-center">
              <div className={`${style.image} d-flex justify-content-center align-items-center`}>
                <img src={Designs} alt="Designs" />
              </div>
              <h3 className='mb-4'>التصميمات</h3>
              <p>{userData?.designs_count || 0} تصميم</p>
            </div>
            <div className="col-xl-3 col-lg-6 text-center">
              <div className={`${style.image} d-flex justify-content-center align-items-center`}>
                <img src={Points} alt="Points" />
              </div>
              <h3 className='mb-4'>النقاط</h3>
              <p>{userData?.points} نقطة</p>
            </div>
            <div className="col-xl-3 col-lg-6 text-center">
              <div className={`${style.image} d-flex justify-content-center align-items-center`}>
                <img src={TotalPoints} alt="Total Points" />
              </div>
              <h3 className='mb-4'>أجمالي النقاط</h3>
              <p>{userData?.total_points || 'N/A'} جنيــــة</p>
            </div>
          </div>
        </div>
      </div>
      <div className={`${style.gallery} pt-5`}>
        <div className="container pb-2">
          <h2>معرض الأعمال</h2>
        </div>
        <div className={`w-100 ${style.bgDark} py-3`}>
          <div className="container pb-5">
            <div className="row pb-5">
              {userData?.gallery?.map((item, index) => (
                <div key={item.id} className="col-lg-4 col-md-6 pt-4 position-relative">
                  <div className={`${style.project} position-relative`}>
                    {isVideoType(item.type) ? (
                      <>
                        <video autoPlay playsInline loop muted className="video-element" src={item.url} />
                        <div className={`${style.overlay} position-absolute start-0 w-100 h-100 d-flex justify-content-center align-items-center gap-2`} >
                          <button className="btn btn-danger" onClick={() => handleDelete(item.id)} >
                            <i className="fa-solid fa-trash-can"></i>
                          </button>
                          <button className="btn btn-danger" onClick={() => openModal(item)}>
                            <i className="fa-solid fa-circle-play"></i>
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <img src={item.url} className="w-100 h-100" alt={item.title} />
                        <div className={`${style.overlay} position-absolute start-0 w-100 h-100 d-flex justify-content-center align-items-center gap-2`} >
                          <button className="btn btn-danger" onClick={() => handleDelete(item.id)} >
                            <i className="fa-solid fa-trash-can"></i>
                          </button>
                          <button className="btn btn-danger" onClick={() => openModal(item)}>
                            <i className="fa-solid fa-magnifying-glass-plus"></i>
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {showImage && (
        <div className={style.bgCover}>
          <div className={style.resultsModal}>
            <button className={style.closeButton} onClick={closeResults}>×</button>
            <div className='w-100 d-flex justify-content-center align-items-center'>
              {modalContent && (modalContent.endsWith('.mp4') || modalContent.endsWith('.avi') || modalContent.endsWith('.mov')) ? (
                <video controls className="w-100">
                  <source src={modalContent} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img src={modalContent} alt="Modal content" className="w-100" />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
