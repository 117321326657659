import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import style from './WithdrawPoints.module.css';
import { BaseUrlContext } from '../../Contexts/BaseUrlContext';


export default function WithdrawPoints() {
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const { baseUrl } = useContext(BaseUrlContext);

  function Scroll() {
    document.body.style.overflow = 'auto'; 
  }

  useEffect(() => {
    Scroll();
  }, []);
  const formik = useFormik({
    initialValues: {
      points: '',
      wallet_phone: '',
      wallet_phone_confirmation: '',
      password: ''
    },
    validationSchema: Yup.object({
      points: Yup.string().required('Required'),
      wallet_phone: Yup.string().required('Required'),
      wallet_phone_confirmation: Yup.string()
        .oneOf([Yup.ref('wallet_phone'), null], 'Wallet phone numbers must match')
        .required('Required'),
      password: Yup.string().required('Required')
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const response = await axios.post(`${baseUrl}api/withdrawal-request`, values, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        setMessageType('success');
        setMessage('Withdrawal request submitted successfully');
        resetForm();
      } catch (error) {
        setMessageType('error');
        setMessage(error.response.data.message);
        console.error('Error submitting withdrawal request', error);
      } finally {
        setSubmitting(false);
      }
    }
  });

  return <>
      <div className={`${style.container1} position-relative`}>
        <p className={`${style.para} text-white text-center position-absolute`}>سحــــب النقـــاط</p>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="container my-5">
          {message && (
            <div className={`alert ${messageType === 'success' ? 'alert-success' : 'alert-danger'}`}>
              {message}
            </div>
          )}
          <div className="row">
            <div className="col-md-6 mb-5">
              <p className={`${style.para3}`}>رقم المحفظة</p>
              <input
                type="text"
                className={`${style.formm} form-control`}
                id="wallet_phone"
                {...formik.getFieldProps('wallet_phone')}
              />
              {formik.touched.wallet_phone && formik.errors.wallet_phone ? (
                <div className="text-danger">{formik.errors.wallet_phone}</div>
              ) : null}
            </div>
            <div className="col-md-6 mb-5">
              <p className={`${style.para3}`}>عدد النقاط المراد سحبها</p>
              <input
                type="text"
                className={`${style.formm} form-control`}
                id="points"
                {...formik.getFieldProps('points')}
              />
              {formik.touched.points && formik.errors.points ? (
                <div className="text-danger">{formik.errors.points}</div>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-5">
              <p className={`${style.para3}`}>كلمة سر الحساب</p>
              <input
                type="password"
                className={`${style.formm} form-control`}
                id="password"
                {...formik.getFieldProps('password')}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="text-danger">{formik.errors.password}</div>
              ) : null}
            </div>
            <div className="col-md-6 mb-5">
              <p className={`${style.para3}`}>تأكيد رقم المحفظة</p>
              <input
                type="text"
                className={`${style.formm} form-control`}
                id="wallet_phone_confirmation"
                {...formik.getFieldProps('wallet_phone_confirmation')}
              />
              {formik.touched.wallet_phone_confirmation && formik.errors.wallet_phone_confirmation ? (
                <div className="text-danger">{formik.errors.wallet_phone_confirmation}</div>
              ) : null}
            </div>
          </div>
          <button type="submit" className={`btn ${style.pur}`} disabled={formik.isSubmitting}>
            طلب سحب النقاط
          </button>
        </div>
      </form>
    <div className="information my-5">
      <p className={`${style.para1} mt-5 pe-4 ps-4 mb-3 mb-sm-5` }>ملاحظات مهمة</p>
      <p className={`${style.para2}  pe-4 mb-4 ps-4`}> <i className="fa-solid fa-circle fs-6 ms-3 mb-2"></i>يرجى كتابة المعلومات المطلوبة بشكل دقيق وفي حالة الخطاء سيتم إلغاء العملية بالكامل</p>
      <p className={`${style.para2}  pe-4 mb-5 ps-4`}> <i className="fa-solid fa-circle fs-6 ms-3"></i>جميع تحويلات التي تتم من خلال فريق الحسابات تتم عن طريق المحافظ الألكترونية مثل ( فودافون كــاش - إتصالات كاش - وي باي - أورنج كاش )</p>
      <p className={`${style.para2}  pe-4 mb-5 ps-4`}> <i className="fa-solid fa-circle fs-6 ms-3"></i>التحويلات المالية تتم خلال يوم واحد فقط في الأسبوع وهو يوم ( الجمعة )</p>
      <p className={`${style.para2}  pe-4 mb-5 ps-4`}> <i className="fa-solid fa-circle fs-6 ms-3"></i>أرسال الطلب يجب أن يكون يوم الثلاثاء أو قبل التحويل المالي بمدة ٧٢ ساعة أو أكثر</p>
      <p className={`${style.para2}  pe-4 mb-5 ps-4`}> <i className="fa-solid fa-circle fs-6 ms-3"></i>يرجى كتابة رقم المحفظة بشكل صحيح وفي حالة الخطاء يرجى العلم بأن نحن غير مسؤلين عن التحويل</p>
      <p className={`${style.para2}  pe-4 mb-5 ps-4`}> <i className="fa-solid fa-circle fs-6 ms-3"></i>البيانات الخاصة بك يجب عليك الأحتفاظ بها و عدم مشاركتها مع الغير</p>
      <p className={`${style.para2}  pe-4 mb-5 ps-4`}> <i className="fa-solid fa-circle fs-6 ms-3"></i>كلمة السر المطلوبة في إجراء عملية سحب هي كلمة سر حسابك على الأكاديمية</p>
      <p className={`${style.para2}  pe-4 mb-5 ps-4`}> <i className="fa-solid fa-circle fs-6 ms-3"></i>في حالة التأخير أو الشكاوي يرجى التواصل مع رقم ( ٠١١٤٠٦٦١٦٢٧ )</p>
      <p className={`${style.para2}  pe-4 mb-5 ps-4`}> <i className="fa-solid fa-circle fs-6 ms-3"></i>قيمة النقطة الواحدة ١٠٠ جنية مصري فقط لاغير ( مئة جنية مصري ) فقط لا غير </p>
      <p className={`${style.para2}  pe-4 mb-5 ps-4`}> <i className="fa-solid fa-circle fs-6 ms-3"></i>غير مسموح بإلغاء العملية المقدمة</p>
      <p className={`${style.para2}  pe-4 mb-5 ps-4`}> <i className="fa-solid fa-circle fs-6 ms-3"></i>غير مسموح بإلغاء أشتراك أحد الطلاب بعد عملية التحويل</p>
  </div>
  </>
}
