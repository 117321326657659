import React from 'react'
import style from './PrivacyPolicy.module.css'
export default function PrivacyPolicy() {
  return <>
    <div className={`${style.container1} d-flex justify-content-center`}>
      <p className={`${style.para} text-white text-center mt-5`}>سياسة الخصوصية</p>
    </div>
    <p className={`${style.para11} pe-4 pe-sm-5 ps-4 `}>بهدف حماية العملاء و تصفية الزيارات المشبوهة</p>
    <p className={`${style.para2} pe-4 pe-sm-5 ps-4 mb-5`}>في أكاديمية إتقان نقوم بجمع الموقع الغير دقيق و رقم IP و عدد مرات زيارة المستخدم لصفحاتنا و مكان تواجده ” البلد ”
و الوقت المنصرم لمشاهدة الصفحة . كما يمكننا التعــــــرف على استخدام التصفـــح الخفي و كشــــــف الخــــــــــــــــوادم الوكيلة
و عناويـــــن DNS و محاولات تسجيـــــــــــل الدخـــــــول للصفحات المحمية ، ذلك بهدف حماية الموقـــــــع و بيانات العمــــــــــــلاء
بما يحقق بيئة آمنة للتعلم.</p>
    <p className={`${style.para2} pe-4 pe-sm-5 ps-4 mb-5 pb-5`}>كما يتم عند الإشتباه باستعمال غير طبيعي تسجيل التحركات الدقيقة اثناء تصفح الزائر للموقع ( HEAT MAP )
      و الحصــــــــــول على معلومــــــــــــات كاملــــــة حول الزائر المشبــــــوه و تسجيل جميع البيانــــــــــات المتعلقـــــــــــــة بالزائــــــــــــر</p>
    {/* متى نقوم بالكشف عن معلوماتك الشخصية ؟ */}
    <p className={`${style.para11} pe-4 pe-sm-5 ps-4 `}>متى نقوم بالكشف عن معلوماتك الشخصية ؟</p>
    <p className={`${style.para2} pe-4 pe-sm-5 ps-4`}>كيف نقوم بتخزين بياناتك الشخصية ؟</p>
    <p className={`${style.para2} pe-4 pe-sm-5 ps-4 mb-5`}>تعتبر خصوصية العميل في اكاديمية إتقان ذات أولوية عالية . و من منطلق إهتمام أكاديمية إتقان بخصوصية العميل .
    لا نسمح بالإتجار بالأرقام أو العناوين المسجلة لدينا.</p>
    <p className={`${style.para2} pe-4 pe-sm-5 ps-4 mb-5`}>و عليه فإن البيانات الشخصية الخاصة بك مؤمنة بتأميـــــن إلكترونــــــي ( تشفير ٢٥٦ بت ) بالاضافة لبرنامج حماية عام
    على الموقع يمنع وجود اى مخترقين للمعلومات او برجيات خبيثة.</p>
    <p className={`${style.para2} pe-4 pe-sm-5 ps-4 mb-5 pb-5`}>يحق لموقع أكاديمية إتــــــــــــقان في الحالات المطابقة و المشابهة لهذه الحـــــــــالة الحصول على ملعومـــــــــات اضافية لتصفية
الزيارات غير المرغوبة. </p>
    
  </>
}
