import React from 'react'
import style from './Protectedroute.module.css'
import { Navigate } from 'react-router-dom'
export default function Protectedroute(props) {
  console.log(props);
 if(localStorage.getItem('token')){
  return props.children
 }
 else{
  return <Navigate to={'/login'}></Navigate>
 }
}
