import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import style from './Register.module.css';
import email from '../../Assets/images/email.PNG';
import location from '../../Assets/images/location.PNG';
import { BaseUrlContext } from '../../Contexts/BaseUrlContext';
import { faDirections } from '@fortawesome/free-solid-svg-icons';

export default function Register() {
  const [successMessage, setSuccessMessage] = useState('');
  const [contactMessage, setContactMessage] = useState('');
  const [categories, setCategories] = useState([]);
  const { baseUrl } = useContext(BaseUrlContext);

  useEffect(() => {
    GetCategories();
  }, []);

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      age: '',
      gender: '',
      job_title: '',
      category_id: '',
      email: '',
      password: '',
      password_confirmation: '',
      phone: '' // Added phone field
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required('مطلوب'),
      last_name: Yup.string().required('مطلوب'),
      age: Yup.number().required('مطلوب').min(1, 'العمر يجب أن يكون أكبر من 1'),
      gender: Yup.string().required('مطلوب'),
      job_title: Yup.string().required('مطلوب'),
      category_id: Yup.string().required('مطلوب'),
      email: Yup.string().email('يجب أن يكون بريد إلكتروني صالح').required('مطلوب'),
      password: Yup.string().required('مطلوب').min(8, 'يجب أن تكون كلمة المرور 8 أحرف على الأقل'),
      password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'كلمات المرور يجب أن تتطابق').required('مطلوب'),
      phone: Yup.string()
        .matches(/^(011|010|012|015)\d{8}$/, 'يجب أن يكون رقم الهاتف 11 رقمًا ويبدأ بـ 011 أو 010 أو 012 أو 015')
        .required('مطلوب') 
    }),
    
    onSubmit: values => {
      axios.post(`${baseUrl}api/auth/register`, values)
        .then(response => {
          setSuccessMessage('تم تسجيل حسابك بنجاح قم بتفعيل حسابك على  gmail');
          formik.resetForm();
        })
        .catch(error => {
          setSuccessMessage(' عذرًا فشلت عملية إنشاء حساب الرجاء المحاولة مرة أخرى .');
        });
    }
  });

  async function GetCategories() {
    try {
      let { data } = await axios.get(`${baseUrl}api/categories`);
      setCategories(data.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  }

  const handleContactSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    const data = {
      name: formData.get('name'),
      email: formData.get('email'),
      phone: formData.get('phone'),
      message: formData.get('message')
    };

    axios.post(`${baseUrl}api/send-inquiry`, data)
      .then(response => {
        setContactMessage('تم إرسال استفسارك بنجاح');
        e.target.reset();
      })
      .catch(error => {
        setContactMessage('فشل في إرسال الاستفسار، حاول مرة أخرى');
      });
  }

  return (
    <>
      <p className={`${style.para}`}> سجل الأن </p>
      <p className={`${style.para2}`}>عند الأنتهاء من تسجيل البيانات سوف تتلقى رسالة نصيه على بريدك الألكتروني لتأكيد الحساب ..</p>
      {successMessage ? <div className={`alert ${successMessage.includes('نجاح') ? 'alert-success' : 'alert-danger'} ${style.rtl}`}>
        {successMessage}
      </div>
        : ''}
      <div className="container my-5">
        <form onSubmit={formik.handleSubmit}>
          <div className="row mb-5" style={{direction: "rtl"}}>
            <div className={`col-md-6 ${style.paraa3}`}>
              <p className={`${style.para3}`}>الأسم الأول</p>
              <input
                type="text"
                className={`${style.formm} form-control ${formik.touched.first_name && formik.errors.first_name ? style.errorBorder : ''}`}
                {...formik.getFieldProps('first_name')}
              />
              {formik.touched.first_name && formik.errors.first_name ? <div className={style.errorMessage}>{formik.errors.first_name}</div> : null}
            </div>
            <div className="col-md-6">
              <p className={`${style.para3}`}>الأسم الاخير</p>
              <input
                type="text"
                className={`${style.formm} form-control ${formik.touched.last_name && formik.errors.last_name ? style.errorBorder : ''}`}
                {...formik.getFieldProps('last_name')}
              />
              {formik.touched.last_name && formik.errors.last_name ? <div className={style.errorMessage}>{formik.errors.last_name}</div> : null}
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-6 position-relative">
              <p className={`${style.para3}`}>النـــــــوع</p>
              <select
                className={`${style.formm} ${style.selectArrow} form-control text-end ${formik.touched.gender && formik.errors.gender ? style.errorBorder : ''}`}
                {...formik.getFieldProps('gender')}
              >
                <option value="">اختر النوع</option>
                <option value="ذكر">ذكر</option>
                <option value="أنثى">أنثى</option>
              </select>
              <i className="fas fa-chevron-down position-absolute fs-5" style={{ left: '30px', top: '78%', transform: 'translateY(-50%)' }}></i>
              {formik.touched.gender && formik.errors.gender ? <div className={style.errorMessage}>{formik.errors.gender}</div> : null}
            </div>
            <div className={`col-md-6 ${style.paraa3}`}>
              <p className={`${style.para3}`}>العمر ( السن )</p>
              <input
                type="text"
                className={`${style.formm} form-control ${formik.touched.age && formik.errors.age ? style.errorBorder : ''}`}
                {...formik.getFieldProps('age')}
              />
              {formik.touched.age && formik.errors.age ? <div className={style.errorMessage}>{formik.errors.age}</div> : null}
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-6 position-relative">
              <p className={`${style.para3}`}>المجال المراد دراسته</p>
              <select
                className={`${style.formm} ${style.selectArrow} form-control text-end ${formik.touched.category_id && formik.errors.category_id ? style.errorBorder : ''}`}
                {...formik.getFieldProps('category_id')}
              >
                <option value="">اختر المجال</option>
                {categories.map(category => (
                  <option key={category.id} value={category.id}>{category.name}</option>
                ))}
              </select>
              <i className="fas fa-chevron-down position-absolute fs-5" style={{ left: '30px', top: '78%', transform: 'translateY(-50%)' }}></i>
              {formik.touched.category_id && formik.errors.category_id ? <div className={style.errorMessage}>{formik.errors.category_id}</div> : null}
            </div>
            <div className={`col-md-6 ${style.paraa3}`}>
              <p className={`${style.para3}`}>الوظيفـــــة</p>
              <input
                type="text"
                className={`${style.formm} form-control ${formik.touched.job_title && formik.errors.job_title ? style.errorBorder : ''}`}
                {...formik.getFieldProps('job_title')}
              />
              {formik.touched.job_title && formik.errors.job_title ? <div className={style.errorMessage}>{formik.errors.job_title}</div> : null}
            </div>
          </div>
          <div className="row mb-5">
            <div className={`col-md-12 ${style.paraa3}`}>
              <p className={`${style.para3}`}>الإيميل الخاص بك</p>
              <input
                type="email"
                className={`${style.formm} form-control ${formik.touched.email && formik.errors.email ? style.errorBorder : ''}`}
                {...formik.getFieldProps('email')}
              />
              {formik.touched.email && formik.errors.email ? <div className={style.errorMessage}>{formik.errors.email}</div> : null}
            </div>
          </div>
          <div className="row mb-5" style={{direction: "rtl"}}>
          <div className={`col-md-6 ${style.paraa3}`}>
              <p className={`${style.para3}`}>كلمة السر</p>
              <input
                type="password"
                className={`${style.formm} form-control ${formik.touched.password && formik.errors.password ? style.errorBorder : ''}`}
                {...formik.getFieldProps('password')}
              />
              {formik.touched.password && formik.errors.password ? <div className={style.errorMessage}>{formik.errors.password}</div> : null}
            </div>
            <div className="col-md-6">
              <p className={`${style.para3}`}>تأكيد كلمة السر</p>
              <input
                type="password"
                className={`${style.formm} form-control ${formik.touched.password_confirmation && formik.errors.password_confirmation ? style.errorBorder : ''}`}
                {...formik.getFieldProps('password_confirmation')}
              />
              {formik.touched.password_confirmation && formik.errors.password_confirmation ? <div className={style.errorMessage}>{formik.errors.password_confirmation}</div> : null}
            </div>
          </div>
          <div className="row mb-5">
            <div className={`col-md-12 ${style.paraa3}`}>
              <p className={`${style.para3}`}>رقم الهاتف</p>
              <input
                type="text"
                className={`${style.formm} form-control ${formik.touched.phone && formik.errors.phone ? style.errorBorder : ''}`}
                {...formik.getFieldProps('phone')}
              />
              {formik.touched.phone && formik.errors.phone ? <div className={style.errorMessage}>{formik.errors.phone}</div> : null}
            </div>
          </div>
          <div className="row">
            <div className="col-md-9">
              <button type="submit" className={`btn ${style.btt}`}>إنشاء حساب</button>
            </div>
          </div>
        </form>
      </div>

      <div className={`${style.container2} `} style={{ marginTop: "20%" }}>
        <div className="container">
          <div className="row">
            <div className={`col-md-6 ${style.kn}`} style={{ paddingRight: '11rem' }}>
              <p className='text-end'><img src={location} alt="" /></p>
              <p className={`${style.location}`}>العـــــــــنوان</p>
              <p className={`${style.location1}`}>محافظة - الجيزة - ٦ أكتوبر - المحور المركزي - الحي العاشر</p>
              <p className={`${style.location1}`}>بجوار سيلفر مول - ETE  اكاديمية</p>
            </div>
            <div className={`col-md-5 ${style.kn2}`} style={{ paddingRight: '4rem' }}>
              <p className='text-end'><img src={email} alt="" style={{ height: "95px" }} /></p>
              <p className={`${style.location}`}>البريـــــد الإلكترونـــــي</p>
              <p className={`${style.location1}`}>etqanacademy29@gmail.com</p>
            </div>
          </div>
        </div>
      </div>
      <div className={`${style.container3}`}>
        <div className="container">
          <div className={`${style.formTitle} my-5`}>أرسل استفسارك</div>
          {contactMessage ? <div className={`alert alert-success ${style.rtl}`}>{contactMessage}</div> : ''}
          <form onSubmit={handleContactSubmit}>
            <div className={`${style.formGroup}`}>
              <input type="text" className={`${style.formControl} my-3`} placeholder="الاسم بالكامل" name="name" />
            </div>
            <div className={`${style.formGroup} my-3`}>
              <input type="email" className={`${style.formControl} my-3`} placeholder="البريد الإلكتروني" name="email" />
            </div>
            <div className={`${style.formGroup} my-3`}>
              <input type="text" className={`${style.formControl} my-3`} placeholder="رقم الهاتف" name="phone" />
            </div>
            <div className={`${style.formGroup} my-3`}>
              <textarea className={`${style.formControl} my-3`} rows="4" placeholder="الرسالة *" name="message"></textarea>
            </div>
            <button type="submit" className={`${style.btnSubmit} mt-3 mb-5`}>أرسل</button>
          </form>
        </div>
      </div>
    </>
  );
}
