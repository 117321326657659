import React from 'react'
import style from './ContactUs.module.css'
import { Link } from 'react-router-dom'
import ContactManager from '../../Assets/images/ايقون-تواصل-مع-الأدارة.png'
import ContactCustomerService from '../../Assets/images/أيقون-تواصل-مع-خدمة-العملاء.png'
import Location from '../../Assets/images/أيقون-العنوان.png'
import Facebook from '../../Assets/images/أيقون-فيسبوك.png'
import Instagram from '../../Assets/images/أيقون-انستجرام.png'
import CommunicationOfficialPages from '../../Assets/images/أيقون-التواصل-على-الصفحات-الرسمية.png'
export default function Contact() {
  return <>
  <div className={`${style.contact}`}>
    <section className="mt-5">
        <div className="container">
            <div className="d-flex justify-content-between align-items-center py-5">
                <div className={`${style.word}`}>
                    <h2>للتواصل مع الأدارة</h2>
                    <p className="my-4">يمكنك التواصل مع الأدارة الخاصة بالأكاديمية بشكل مباشر من خلال الأرقام التالية</p>
                    <div className="d-flex justify-content-start align-items-center gap-3">
                        <h4 className={style.number}>٠١١٤٠٦٦١٦٢٧</h4>
                        <h4 className={style.number}>٠١١٤٧٠٩٩٠٩٩</h4>
                    </div>
                </div>
                <div className={`${style.image} d-lg-block d-sm-none`}>
                    <img src={ContactManager} alt="ايقون-تواصل-مع-الأدارة" />
                </div>
            </div>
        </div>
    </section>
    <section className="mt-5">
        <div className="container">
            <div className="d-flex justify-content-between align-items-center py-5">
                <div className={`${style.word}`}>
                    <h2>للتواصل مع خدمة عملاء إتقان</h2>
                    <p className="my-4">يمكنك التواصل مع خدمة عملاء إتقان أكاديمي من خلال الأرقام التالية</p>
                    <div className="d-flex justify-content-start align-items-center gap-3">
                        <h4 className={style.number}>٠١١٤٠٦٦١٦٢٧</h4>
                        <h4 className={style.number}>٠١١١٧٧٧٢٨٦٦</h4>
                    </div>
                    <div className="d-flex justify-content-start align-items-center gap-3">
                        <h4 className={style.number}>٠٢٣٨٨٧٨٧١١٠</h4>
                        <h4 className={style.number}>٠١٥٥٥٤٤١٨٤٦</h4>
                    </div>
                </div>
                <div className={`${style.image} d-lg-block d-sm-none`}>
                    <img src={ContactCustomerService} alt="ايقون-تواصل-مع-خدمة-العملاء" />
                </div>
            </div>
        </div>
    </section>
    <section className="mt-5">
        <div className="container">
            <div className="d-flex justify-content-between align-items-center py-5">
                <div className={`${style.word}`}>
                    <h2>عنوان إتقان أكاديمي</h2>
                    <p className="mb-4">يمكنك زيارة المقر الرئيسي للأكاديمية من خلال العنوان التال</p>
                    <h4>محافظة - الجيزة - ٦ أكتوبر - المحور المركزي - الحي العاشر
                        أكاديمية ETE - بجوار سيلفر مول </h4>
                    <p className="mt-4">يمكنك الضغط على العنوان لتحويلك على google map</p>
                </div>
                <div className={`${style.image} d-lg-block d-sm-none`}>
                    <img src={Location} alt="ايقون-العنوان" />
                </div>
            </div>
        </div>
    </section>
    <section className="my-5">
        <div className="container">
            <div className="d-flex justify-content-between align-items-center py-5">
                <div className={`${style.word}`}>
                    <h2>للتواصل عبر الصفحات الرسمية</h2>
                    <p className="mb-4">يمكنك التواصل معنا عبر الصفحات الرسمية من خلال الصفحات التالية</p>
                    <div className={`${style.Media} d-md-flex d-sm-block justify-content-start align-items-center gap-3`}>
                        <div className={`${style.Media1} d-flex justify-content-center align-items-center gap-2`}>
                            <Link to="https://www.instagram.com/etqanacademic/">https://www.instagram.com/etqanacademic/</Link>
                            <img src={Instagram} alt="أيقون-انستجرام" />
                        </div>
                        <div className={`${style.Media1} d-flex justify-content-center align-items-center gap-2`}>
                            <Link to="https://www.facebook.com/profile.php?id=61552011899556">https://www.facebook.com/profile.php?id=61552011899556</Link>
                            <img src={Facebook} alt="أيقون-فيسبوك" />
                        </div>
                    </div>
                </div>
                <div className={`${style.image} d-lg-block d-sm-none`}>
                    <img src={CommunicationOfficialPages} alt="ايقون-التواصل-على-الصفحات-الرسمية" />
                </div>
            </div>
        </div>
    </section>
  </div>
  </>
}
